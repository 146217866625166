import React from 'react';
import logo from '../../assets/images/logo.png';
import './index.css';

const Navbar = () => {
  return (
    <>
      <div className="navbar-container">
        <div className="navbar-emblem">
          <a href="/">
            <img src={logo} alt="Court of Degeneracy Logo" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Navbar;
