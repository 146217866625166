import React from 'react';
import cod_logo from '../../assets/images/logo.png';
import cod_banner from '../../assets/images/cod_banner.jpg';
import dod_banner from '../../assets/images/dod_banner.jpg';
import './index.css';

const Home = () => {
  return (
    <>
      <div className="home-container" id="about">
        <hr className="gray-line mb-5"></hr>

        <p className="text">
          <a href="https://www.deckofdegeneracy.com/" target="_blank" rel="noreferrer">
            <img src={dod_banner} alt="deck of degeneracy banner" />
          </a>
          <a href="https://www.deckofdegeneracy.com/" target="_blank" rel="noreferrer">
            Deck of Degeneracy
          </a>
        </p>

        <hr className="gray-line mb-5"></hr>

        <p className="text">
          <a href="https://www.courtofdegeneracy.com/" target="_blank" rel="noreferrer">
            <img src={cod_banner} alt="court of degeneracy banner" />
          </a>
          <a href="https://www.courtofdegeneracy.com/" target="_blank" rel="noreferrer">
            Court of Degeneracy
          </a>
        </p>

        <hr className="gray-line mb-5"></hr>

        <p className="text">
          <a href="https://opensea.io/collection/dodart" target="_blank" rel="noreferrer">
            Deck of Degeneracy Art Collection
          </a>
        </p>

        <hr className="gray-line mb-5"></hr>

        <p className="text">
          <a href="https://discord.gg/Dna6465WQn" target="_blank" rel="noreferrer">
            Discord
          </a>
        </p>

        <hr className="gray-line mb-5"></hr>

        <p className="text">
          <a href="https://twitter.com/DegeneracyNFT" target="_blank" rel="noreferrer">
            Twitter
          </a>
        </p>

        <hr className="gray-line mb-5"></hr>

        <p className="text">
          <a href="https://twitter.com/jonathanLittle" target="_blank" rel="noreferrer">
            Jonathan Little Twitter
          </a>
        </p>

        <hr className="gray-line mb-5"></hr>

        <p className="text">
          <a href="https://twitter.com/WesHenryArt" target="_blank" rel="noreferrer">
            Wes Henry Twitter
          </a>
        </p>

        <hr className="gray-line mb-5"></hr>
      </div>

      <div className="tail">
        <div className="tail-1">
          <a href="/">
            <img src={cod_logo} alt="Degeneracy NFTs Logo" />
          </a>
        </div>
        <div className="tail-2">Copyright &copy; 2023 Degeneracy NFTs. All Rights Reserved.</div>
      </div>
    </>
  );
};

export default Home;
